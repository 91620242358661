export const locales = [
  {
    code: "en",
    iso: "en-US",
    name: "English",
  },
  {
    code: "de",
    iso: "de-DE",
    name: "Deutsch",
  },
  {
    code: "fr",
    iso: "fr-FR",
    name: "Français",
  },
];

export const localeDefault = locales[0];
