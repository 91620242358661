import { locales, localeDefault } from "./constants";

export const extractLocale = (urlPathname) => {
  const path = urlPathname.split("/");

  const first = path[1];
  const possibleLocales = locales.filter((locale) => locale.code !== localeDefault.code);
  let locale, urlPathnameWithoutLocale;

  if (possibleLocales.some((locale) => locale.code === first)) {
    locale = first;
    urlPathnameWithoutLocale = "/" + path.slice(2).join("/");
  } else {
    locale = localeDefault.code;
    urlPathnameWithoutLocale = urlPathname;
  }

  return { locale, urlPathnameWithoutLocale };
};
