import cubeCounter from "./cube-counter/index.js";
import flagQuiz from "./flag-quiz/index.js";
//import shapeNation from "./shape-nation/index.js";

const imports = {
  cubeCounter,
  flagQuiz,
  //shapeNation,
};

const extensions = {
  "cube-counter": () => import("./cube-counter/extension.js"),
  "flag-quiz": () => import("./flag-quiz/extension.js"),
  //"shape-nation": () => import("./shape-nation/extension.js"),
};

const games = {};
export default games;

Object.keys(imports).forEach((key) => {
  const game = imports[key];
  games[game.slug] = { extended: false, ...game };
});

export const engines = {};

export const importEngines = async () => {
  return new Promise((resolve) => {
    Object.keys(games).forEach(async (game) => {
      engines[game] = await import(`./${game}/engine/index.js`);
      if (Object.keys(engines).length === Object.keys(games).length) resolve();
    });
  });
};

export const extendGame = async (slug) => {
  if (!games[slug]) {
    console.error(`Game "${slug}" not found.`);
    return;
  }

  if (games[slug].extended) return games[slug];

  try {
    const extension = await extensions[slug]();
    if (games[slug].extended) return games[slug];

    games[slug] = {
      ...games[slug],
      extended: true,
    };

    Object.keys(extension.default).forEach((key) => {
      if (games[slug][key]) {
        games[slug][key] = {
          ...games[slug][key],
          ...extension.default[key],
        };
      } else {
        games[slug][key] = extension.default[key];
      }
    });

    if (games[slug]?.events) {
      Object.keys(games[slug].events).forEach((eventKey) => {
        const event = games[slug].events[eventKey];
        games[slug].events[eventKey] = `${slug}:${event}`;
      });
    }
  } catch (error) {
    console.error(`Failed to load extension for "${slug}":`, error);
  }

  return games[slug];
};
