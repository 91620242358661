export default {
  slug: "cube-counter",
  theme: {
    background: "rgb(6, 190, 218)",
    text: {
      400: "rgb(255, 255, 255)",
      500: "rgb(255, 255, 255)",
    },
  },
};
