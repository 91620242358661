export default {
  slug: "flag-quiz",
  theme: {
    background: "rgb(61, 173, 243)",
    text: {
      400: "rgb(255, 255, 255)",
      500: "rgb(255, 255, 255)",
    },
  },
};
